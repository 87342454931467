@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

body {
    font-family: "Roboto", sans-serif;
    font-weight: 400
}

.hide-scrollbar::-webkit-scrollbar{
    display: none;
}

.no_highlights{
  -webkit-tap-highlight-color: transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  display: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.animate-fade-in {
  animation: fadeIn 0.3s ease-in-out forwards;
}

.animate-fade-out {
  animation: fadeOut 0.3s ease-in-out forwards;
}

/* Remove focus outline but maintain accessibility */
*:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* For keyboard navigation, show a subtle outline */
*:focus-visible {
  outline: 2px solid #3b82f6 !important;
  outline-offset: 2px;
}

/* Additional rules for buttons and interactive elements */
button:focus,
button:focus-visible,
button:active,
a:focus,
a:focus-visible,
a:active {
  outline: none !important;
  box-shadow: none !important;
  -webkit-tap-highlight-color: transparent !important;
}

/* Specific for BottomNav buttons */
.bottom-nav button:focus,
.bottom-nav button:focus-visible,
.bottom-nav button:active,
.bottom-nav a:focus,
.bottom-nav a:focus-visible,
.bottom-nav a:active {
  outline: none !important;
  box-shadow: none !important;
  -webkit-tap-highlight-color: transparent !important;
}

